/* eslint-disable no-implicit-coercion */
import { ReactElement } from "react";
import { Anchor } from "@twilio-paste/core/anchor";

const protocol = "((?:http|https|rtsp):\\/\\/)";
const domainAndSubdomains = "(?:(?:[a-zA-Z0-9][-]*)*[a-zA-Z0-9]\\.)+";
const genericTopLevelDomains =
    "aero|arpa|asia|app|" +
    "biz|buzz|" +
    "cat|com|coop|credit|" +
    "edu|" +
    "gov|" +
    "info|int|" +
    "jobs|" +
    "mil|mobi|museum|" +
    "name|net|" +
    "org|" +
    "pro|" +
    "tel|travel";
const countryTopLevelDomains =
    "a[cdefgilmnoqrstuwxz]|" +
    "b[abdefghijlmnoqrstvwyz]|" +
    "c[acdfghiklmnoruvwxyz]|" +
    "d[ejkmoz]|" +
    "e[ceghrstu]|" +
    "f[ijkmor]|" +
    "g[abdefghilmnpqrstuwy]|" +
    "h[kmnrtu]|" +
    "i[delmnoqrst]|" +
    "j[emop]|" +
    "k[eghimnprwyz]|" +
    "l[abcikrstuvy]|" +
    "m[acdefghklmnopqrstuvwxyz]|" +
    "n[acefgilopruz]|" +
    "om|" +
    "p[aefghklmnrstwy]|" +
    "qa|" +
    "r[eosuw]|" +
    "s[abcdeghijklmnorstuvxyz]|" +
    "t[cdfghjklmnoprtvwz]|" +
    "u[agkmsyz]|" +
    "v[aceginu]|" +
    "w[fs]|" +
    "y[etu]|" +
    "z[amw]";
const port = "(?:\\:\\d{1,5})?";
const path = '(?:[/?#][^\\s"]*)?';
const startOfMatchedUrl = "(?:\\b|^)";
const endOfMatchedUrl = "(?:\\b|$)";
const isValidUrl = urlString=> {
    const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
return !!urlPattern.test(urlString);
};
const isValidEmail = function(email) {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return re.test(email)
};
// only captured group is the protocol, e.g. https://
const urlRegexString = `${startOfMatchedUrl}(?:${protocol}\\S+|(?:${protocol})?${domainAndSubdomains}(?:${genericTopLevelDomains}|${countryTopLevelDomains})${port}${path})${endOfMatchedUrl}`;
const urlRegex = new RegExp(urlRegexString, "gi");

export const parseMessageBody = (body: string, belongToCurrentUser: boolean) => {
    const renderedResult: Array<ReactElement | string> = [];

    const lineBreakRegexp = new RegExp(/[\n\r]/);
    const lines = body.split(lineBreakRegexp);
    lines.forEach((line) => {
        let index = 0;
        let regexResult;

        while ((regexResult = urlRegex.exec(line))) {
            // add all regular body text that comes before the url
            if(!isValidEmail(line)) {
                renderedResult.push(line.substring(index, regexResult.index));
            }

            const [url, urlProtocol] = regexResult;
            const urlHref = urlProtocol ? url : `http://${url}`;

            if(isValidEmail(line)) {
                renderedResult.push(
                    <Anchor href={line} key={index} variant={belongToCurrentUser ? "inverse" : "default"}>
                        {line}
                    </Anchor>
                );
            } else {
                renderedResult.push(
                    <Anchor href={urlHref} key={index} variant={belongToCurrentUser ? "inverse" : "default"}>
                        {url}
                    </Anchor>
                );
            }

            index = regexResult.index; //int(regexResult[0].length);
        }
        
        // // add all regular body text that comes after the url
        if (index < line.length) {
            let restOfText = line.substring(index, line.length);
            if (isValidUrl(restOfText) || isValidEmail(restOfText)) {
            restOfText = "";
            }

            renderedResult.push(restOfText);
        }

        renderedResult.push("\n");
    });

    // remove the newline added after the last parsed line
    renderedResult.pop();

    return renderedResult;
};
