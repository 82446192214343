/** First, introductory page of chatbot. */

/* eslint-disable multiline-comment-style */
//import { Input } from "@twilio-paste/core/input";
//import { Label } from "@twilio-paste/core/label";
import { Box } from "@twilio-paste/core/box";
//import { TextArea } from "@twilio-paste/core/textarea";
import { FormEvent } from "react";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase/*, updatePreEngagementData*/ } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { introStyles, fieldStyles, titleStyles, formStyles, fullScreenFormStyles, fullScreenTitle, fullScreenIntro, formContainer } from "./styles/PreEngagementFormPhase.styles";

export const PreEngagementFormPhase = () => {
    const { name, email, query } = useSelector((state: AppState) => state.session.preEngagementData) || {name:"test-user",email:"jpomerenke@altuscampus.com",query:"new convo"};
    const dispatch = useDispatch();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));

        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName: name,
                    email,
                    query
                }
            });
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };
    // To display which flow in the welcome text, switch case based on url pathname 
    const getWelcomeText = () => {
        switch (window.location.pathname) {
            case "/hayat_pharmacy/":
                return "Welcome to Hayat Pharmacy Interactive Chat, I am Michelle";
            // Add more cases for other websites
            default:
                return "Welcome to the SDM Interactive Chat, I am Michelle";
        }
    };

    const setSpanishLanguage = () => {
        window.location.href=`https://${window.location.hostname}/asthma_espanol${window.location.search}`;
    }

    // const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    //     if (e.key === "Enter" && !e.shiftKey) {
    //         e.preventDefault();
    //         handleSubmit(e);
    //     }
    // };

    // check url parameters for full screen chatbot
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const fullScreenDemo = urlParams.get('fullscreen');
    const centerFullScreen = (fullScreenDemo === "true");

    return (
        <>
            <Header />
            <Box {...(centerFullScreen ? formContainer : null)}>
                <NotificationBar />
                <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...(centerFullScreen ? fullScreenFormStyles: formStyles)}>
                    {!["/asthma_espanol/"].includes(window.location.pathname) ? (
                        <div>
                            <Text {...(centerFullScreen ? fullScreenTitle : titleStyles)} as="h3">
                                Hi there!
                            </Text>
                            <Text {...(centerFullScreen ? fullScreenIntro : introStyles)} as="p">
                                Welcome to the SDM Interactive Chat, I am Michelle.
                            </Text>
                        </div>
                    ) : (
                        <div>
                            <Text {...(centerFullScreen ? fullScreenTitle: titleStyles)} as="h3">
                                Hola
                            </Text>
                            <Text {...(centerFullScreen ? fullScreenIntro : introStyles)} as="p">
                                Bienvenido al Chat Interactivo de SDM, soy Michelle
                            </Text>
                        </div>
                    )}
                    {/*<Box {...fieldStyles}>
                        <Label htmlFor="name">Name</Label>
                        <Input
                            type="text"
                            placeholder="Please enter your name"
                            name="name"
                            data-test="pre-engagement-chat-form-name-input"
                            value={name}
                            onChange={(e) => dispatch(updatePreEngagementData({ name: e.target.value }))}
                            required
                        />
                    </Box>
                    <Box {...fieldStyles}>
                        <Label htmlFor="email">Email address</Label>
                        <Input
                            type="email"
                            placeholder="Please enter your email address"
                            name="email"
                            data-test="pre-engagement-chat-form-email-input"
                            value={email}
                            onChange={(e) => dispatch(updatePreEngagementData({ email: e.target.value }))}
                            required
                        />
                    </Box>

                    <Box {...fieldStyles}>
                        <Label htmlFor="query">How can we help you?</Label>
                        <TextArea
                            placeholder="Ask a question"
                            name="query"
                            data-test="pre-engagement-chat-form-query-textarea"
                            value={query}
                            onChange={(e) => dispatch(updatePreEngagementData({ query: e.target.value }))}
                            onKeyPress={handleKeyPress}
                            required
                        />
                    </Box>*/}
                    {["/", "/asthma/"].includes(window.location.pathname) &&
                        <Box {...fieldStyles} style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                            <Button size="default" variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                                English
                            </Button>
                            <Button variant="primary" onClick={setSpanishLanguage} style={{marginTop: '10px'}} title="Spanish" data-test="pre-engagement-start-chat-button">
                                Spanish
                            </Button>
                        </Box>
                    }
                    {["/asthma_espanol/"].includes(window.location.pathname) &&
                        <Box {...fieldStyles}>
                            <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                                Comenzar
                            </Button>
                        </Box>
                    }
                    {!["/","/asthma/","/asthma_espanol/"].includes(window.location.pathname) &&
                        <Box {...fieldStyles}>
                            <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                                Begin
                            </Button>
                        </Box>
                    }
                </Box>
            </Box>
        </>
    );
};
