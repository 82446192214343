import { BoxStyleProps } from "@twilio-paste/core/box";

export const outerContainerStyles: BoxStyleProps = {
    position: "fixed",
    bottom: "space50",
    right: "space60",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
};

// container stylings for full screen demo chatbot
export const outerContainerFullScreen: BoxStyleProps = {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // overflow: "hidden",
    justifySelf: "center",
    alignSelf: "center",
    height: "100%",
    width: "100%",
    minHeight: "225px"
};

export const innerContainerStyles: BoxStyleProps = {
    boxShadow: "shadow",
    display: "flex",
    flexDirection: "column",
    width: "320px",
    height: "auto",
    maxHeight: "540px",
    minHeight: "225px",
    justifyContent: "center",
    marginBottom: "space50",
    borderRadius: "borderRadius30",
    backgroundColor: "colorBackgroundBody"
};

// styling specific to Wix mobile sites
// export const wixInnerContainer: BoxStyleProps = {
//     boxShadow: "shadow",
//     display: "flex",
//     flexDirection: "column",
//     alignContent: "start",
//     justifyContent: "start",
//     alignSelf: "stretch",
//     width: "75vw",
//     height: "75vh",
//     minHeight: "150px",
//     marginBottom: "space50",
//     borderRadius: "borderRadius30",
//     backgroundColor: "colorBackgroundBody",
// };

// inner container styling for full screen demo
export const innerContainerFullScreen: BoxStyleProps = {
    display: "flex",
    flexDirection: "column",
    alignContent: "start",
    alignSelf: "stretch",
    justifyContent: "start",
    // alignItems: "center",
    width: "100vw",
    height: "100vh",
    minHeight: "225px",
    backgroundColor: "colorBackgroundBody"
};
