/* eslint-disable multiline-comment-style */
import { BoxStyleProps } from "@twilio-paste/core/box";
// import { CSSProperties } from "react";

export const formStyles: BoxStyleProps = {
    borderTopColor: "colorBorderWeaker",
    borderTopWidth: "borderWidth10",
    borderTopStyle: "solid",
    padding: "space20",
};

export const innerInputStyles: BoxStyleProps = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start"
};

// fullscreen styling for button container
export const fullscreenInnerInputStyles: BoxStyleProps = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "space40"
};

export const textAreaContainerStyles: BoxStyleProps = {
    marginTop: "auto",
    marginBottom: "auto",
    flex: 1
};

export const messageOptionContainerStyles: BoxStyleProps = {
    margin: "space30",
    marginLeft: "space20",
    display: "none", //display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "left",

    _notLast: {
        marginRight: "space0"
    }
};

export const filePreviewContainerStyles: BoxStyleProps = {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    maxHeight: "300px",
    position: "relative",
    paddingLeft: "space30",
    paddingRight: "space30"
};

export const quickReplyContainerStyles: BoxStyleProps = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    padding: "space30",
    // justifyContent: "space-between",
    alignItems: "stretch",
    minHeight: "95px",
    width: "100%",
    rowGap: "space20"
};

// export const quickReplyButtonStyles: CSSProperties = {
//     borderColor: "colorBorderBlue",
//     borderWidth: "borderWidth10",
//     width: "100%",
//     borderTopStyle: "solid",
//     padding: "space20",
//     textAlign: "left"
// }
