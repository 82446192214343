/* eslint-disable react/style-prop-object */
// Disable eslint rule for style prop object in React
// 01/24/2024 added comments partially created with ChatGPT -jongley

// Import necessary modules and components
import merge from "lodash.merge";
import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import  PhysicianLookup  from "./components/PhysicianLookup"

// Import local files and modules
import { store } from "./store/store";
import { WebchatWidget } from "./components/WebchatWidget";
import { sessionDataHandler } from "./sessionDataHandler";
import { initConfig } from "./store/actions/initActions";
import { ConfigState } from "./store/definitions";
import { initLogger } from "./logger";
import sunflower from "./assets/sunflower.jpg";
import sdmcentral from "./assets/sdmcentral.png";

// Function to get environment variables based on the server's path
const getEnvVariable = (postEnvVariable) => {
    const pathName = window.location.pathname;
    let appServerName = "REACT";
    let prefix = "";
    if (pathName != "/") {
        appServerName = pathName.replace(/\//g, '');
        prefix = "REACT_APP_";
    }
 
    const envVariableName = prefix + appServerName + postEnvVariable;
    const envVariable = process.env[envVariableName];

    return envVariable;
}

// Default configuration for the chat application
const defaultConfig: ConfigState = {
    serverUrl: getEnvVariable("_APP_SERVER_URL"),
    theme: {
        isLight: true
    },
    fileAttachment: {
        enabled: true,
        maxFileSize: 16777216, // 16 MB
        acceptedExtensions: ["jpg", "jpeg", "png", "amr", "mp3", "mp4", "pdf", "txt"]
    },
    transcript: {
        downloadEnabled: false,
        emailEnabled: false,
        emailSubject: (agentNames) => {
            let subject = "Transcript of your chat";
            
            // Add agent names to the email subject
            if (agentNames.length > 0) {
                subject = subject.concat(` with ${agentNames[0]}`);
                agentNames.slice(1).forEach((name) => (subject = subject.concat(` and ${name}`)));
            }
            
            return subject;
        },
        emailContent: (customerName, transcript) => {
            // Generate email content with customer name and transcript
            return `<div><h1 style="text-align:center;">Chat Transcript</h1><p>Hello ${customerName},<br><br>Please see below your transcript, with any associated files attached, as requested.<br><br>${transcript}</p></div>`;
        }
    }
};

// Function to initialize the webchat application
const initWebchat = async (config: ConfigState) => {
    // const mergedConfig = merge({}, defaultConfig, config);
    sessionDataHandler.setEndpoint(defaultConfig.serverUrl);
    store.dispatch(initConfig(defaultConfig));

    // Initialize the logger
    initLogger();

    // Get the root element for rendering
    const rootElement = document.getElementById("twilio-webchat-widget-root");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Check url param for background, set if true or null
    const backgroundImageCheck = urlParams.get('background');
    let includePreview = (backgroundImageCheck === "true" || backgroundImageCheck == null);

    // Check URL param for fullscreen, do not show background if true
    const fullScreenDemo = urlParams.get('fullscreen');
    if(fullScreenDemo === "true") {
        includePreview = false;
    };

    console.log(window.location.pathname);
    const isPhyisicanLookup = window.location.pathname === '/physicianlookup/';

   

    // Render the webchat application with appropriate styling based on URL parameters
    render(
        <Provider store={store}>
            {isPhyisicanLookup && (
                <PhysicianLookup />
            )}
            {includePreview  && !isPhyisicanLookup && (
                <div style={{height: "100%", width: "100%", position: "absolute", top: "0", zIndex: "1000" }}>
                    <div style={{position: "absolute", top: "0", width: "80vw", minWidth: "350px", maxWidth: "540px", display: "flex", paddingLeft: "44px", paddingTop: "40px", justifyContent: "flex-start", alignItems: "flex-start", zIndex: "5000", flexDirection: "column"}}>
                        <img style={{width: "100%", maxWidth: "500px"}} src={sdmcentral} />
                        <div style={{fontWeight: "bold", fontSize:"min(150%, 25px)", textAlign: "end", marginTop:"-3%", marginLeft: "5px", fontFamily: "Courier New", alignSelf: "flex-end"}}>
                        Interactive Chat Technology
                        </div>
                    </div>
                    <div style={{position: "fixed", top: "160px", height:"calc(100% - 200px)", width: "25px", backgroundColor: "rgba(8,166,239,255)", left: "100px"}}></div>
                    <div style={{position: "fixed", top: "calc(100% - 50px)", left: "100px", backgroundColor: "rgba(8,166,239,255)", height: "25px", width: "calc(100% - 200px)"}}></div>
                    <div style={{position: "fixed", top: "calc(100% - 53px)", left: "calc(100% - 125px)"}}>
                        <i style={{border: "solid rgba(8,166,239,255)", borderWidth: "0 10px 10px 0", display: "inline-block", padding: "11px", transform: "rotate(-45deg)", WebkitTransform: "rotate(-45deg)"}}>
                        </i>
                    </div>
                </div>
            )}
            
            <WebchatWidget />
        </Provider>,
        rootElement
    );

    window.store = store;
};

// Declare global interfaces in the window object
declare global {
    interface Window {
        Twilio: {
            initWebchat: (config: ConfigState) => void;
        };
        store: typeof store;
    }
}

// Expose `initWebchat` function to window object
Object.assign(window, {
    Twilio: {
        initWebchat
    }
});
