import React, { useState, useEffect } from 'react';

function PhyisicianLookup() {
    const [specialists, setSpecialists] = useState([]);

    useEffect(() => {
        fetch('/specialists/getAllSpecialists')
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setSpecialists(data.data.specialists)
            })
            .catch(error => console.error('Error fetching specialists:', error));
    }, []);

    // Render your specialists or simply return null if you want to perform actions without rendering
    return (
        <>
            <div>hello</div>
        </>
    );
}

export default PhyisicianLookup;