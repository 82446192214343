/** First, introductory page of chatbot. Styles page and text. */

/* eslint-disable multiline-comment-style */
import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";
// import { ButtonProps } from "@twilio-paste/core/button";

// styling for container around full screen form
export const formContainer: BoxStyleProps = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%"
}

// general page style
export const formStyles: BoxStyleProps = {
    padding: "space80",
    // paddingTop: "space80",
    overflow: "auto"
};

// full screen page style
export const fullScreenFormStyles: BoxStyleProps = {
    padding: "space80",
    overflow: "hidden",
}

// general title styles
export const titleStyles: TextStyleProps = {
    fontSize: "fontSize70",
    marginBottom: "space60"
};

// full screen title styles
export const fullScreenTitle: TextStyleProps = {
    fontSize: "fontSize90",
    marginBottom: "space60"
};

// general intro styling
export const introStyles: TextStyleProps = {
    marginBottom: "space70",
};

// full screen intro styling
export const fullScreenIntro: TextStyleProps = {
    marginBottom: "space70",
    fontSize: "fontSize70",
    lineHeight: "lineHeight60"
}

// general styling for buttons container
export const fieldStyles: BoxStyleProps = {
    margin: "space20"
};

// button styles for full screen
// export const buttonStyles: ButtonProps = {
//     size: "default",
// }