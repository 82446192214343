import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";

import { containerStyles, titleStyles, fullScreenContainer, fullScreenTitle } from "./styles/Header.styles";

export const Header = ({ customTitle }: { customTitle?: string }) => {

    // get url param for full screen demo
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const fullScreenDemo = urlParams.get("fullscreen");
    const centerFullScreen = (fullScreenDemo === "true");

    // apply conditional styling based on presence of fullscreen url param
    const styles = centerFullScreen ? fullScreenContainer : containerStyles;
    const textStyles = centerFullScreen ? fullScreenTitle : titleStyles;

    // header styling specific to full screen demo
    return (
        <Box as="header" {...styles}>
            <Text as="h1" {...textStyles}>
                {customTitle || "Interactive Chat Technology (ICT)"}
            </Text>
        </Box>
    )
};
