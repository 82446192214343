import { Box } from "@twilio-paste/core/box";
import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { changeExpandedStatus } from "../store/actions/genericActions";
import { AppState } from "../store/definitions";
import { containerStyles} from "./styles/EntryPoint.styles";

export const EntryPoint = () => {
    // initialize useDispatch, useSelector
    const dispatch = useDispatch();
    const expanded = useSelector((state: AppState) => state.session.expanded);

    // Q: queryString required for searchParams in tsx?
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // check url for expanded param
    const expandedCheck = urlParams.get('expanded');
    const expandChat = (expandedCheck === "true" );
    
    // send message object to parent window w/ val of true or false
    if(expanded == true || expandChat) {
        console.log(expanded);
        window.parent.postMessage({message: "expanded", value: true}, "*");
    } else {
        window.parent.postMessage({message: "expanded", value: false}, "*");
    }

    // open chatbot window automatically onload
    useEffect(() => {
        if (expandChat) {
            dispatch(changeExpandedStatus({ expanded: true }));
        }
    }, []);
    
        return (
            <Box
                as="button"
                data-test="entry-point-button"
                onClick={() => dispatch(changeExpandedStatus({ expanded: !expanded }))}
                {...containerStyles}
            >
                {expanded ? (
                    <ChevronDownIcon decorative={false} title="Minimize chat" size="sizeIcon80" />
                ) : (
                    <ChatIcon decorative={false} title="Open chat" size="sizeIcon60" />
                )}
            </Box>
        );
};
