// Import necessary modules and components from the Twilio Paste library and local files
// 01/24/2024 added comments partially created with ChatGPT -jongley

import { Box } from "@twilio-paste/core/box";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import { AppState, EngagementPhase } from "../store/definitions";
import { PreEngagementFormPhase } from "./PreEngagementFormPhase";
import { LoadingPhase } from "./LoadingPhase";
import { EntryPoint } from "./EntryPoint";
import { innerContainerStyles, outerContainerStyles, outerContainerFullScreen, innerContainerFullScreen } from "./styles/RootContainer.styles";
import { changeExpandedStatus } from "../store/actions/genericActions";

// Function to determine the appropriate component based on the engagement phase
// The EngagementPhase is that state of the chatbot Loading, MessagingCanvas, and PreEngagementForm
const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.PreEngagementForm:
        default:
            return <PreEngagementFormPhase />;
    }
};
  
// Main component for the root container of the chat application
export function RootContainer() {
    const dispatch = useDispatch();
    // Select relevant state properties from the Redux store
    const { currentPhase, expanded } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded
    }));


    const updateSpecialists = () => {
        console.log('hit');
          fetch('/specialists/updateSpecialistsDb', {
            method: 'POST', // or 'PUT'
            headers: {
              'Content-Type': 'application/json',
              // Add any other headers needed for authentication or other purposes
            },
            body: JSON.stringify({
              // Add any data you need to send to the backend for the upsert
            }),
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
              // Handle successful response if needed
            })
            .then(data => {
                console.log(data);
            })
            .catch(error => {
              console.error('Error upserting database:', error);
              // Handle error if needed
            });
    }

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //       // Make the request to your backend to upsert the database
    //       console.log('hit');
    //       fetch('/specialists/updateSpecialistsDb', {
    //         method: 'POST', // or 'PUT'
    //         headers: {
    //           'Content-Type': 'application/json',
    //           // Add any other headers needed for authentication or other purposes
    //         },
    //         body: JSON.stringify({
    //           // Add any data you need to send to the backend for the upsert
    //         }),
    //       })
    //         .then(response => {
    //           if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //           }
    //           // Handle successful response if needed
    //         })
    //         .catch(error => {
    //           console.error('Error upserting database:', error);
    //           // Handle error if needed
    //         });
    //     }, 60000); // Interval set to 1 week (7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    
    //     // Cleanup function to clear the interval when the component unmounts
    //     return () => clearInterval(interval);
    //   }, []); // Empty dependency array ensures this effect runs only once on mount

    // Get URL parameters for customizing the chat window size
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // keeping wixmobile param for now - likely to delete later
    // const wixMobileParam = urlParams.get('wixmobile');
    // const wixMobileWindow = (wixMobileParam === "true");

    // check url for fullscreen param
    const fullScreenDemo = urlParams.get('fullscreen');
    const centerFullScreen = (fullScreenDemo === "true");
    
    useEffect(() => {
        if(centerFullScreen) {
            dispatch(changeExpandedStatus({ expanded: true }));
        }
    }, []);

    const outerStyles = centerFullScreen ? outerContainerFullScreen : outerContainerStyles;

    const innerStyles = centerFullScreen ? innerContainerFullScreen : innerContainerStyles;

    // if(wixMobileWindow) {
    //     return (
    //         <Box id="wixmobile-outer-container">
    //             <Box {...outerContainerStyles}>
    //                 {expanded && (
    //                     <Box data-test="root-container" id="wixmobile-inner-container" {...wixInnerContainer}>
    //                         {getPhaseComponent(currentPhase)}
    //                     </Box>
    //                 )}
    //                 <EntryPoint />
    //             </Box>
    //         </Box>
    //     );
    // } else 
    // if(centerFullScreen) {
    return (
        <Box id="fullscreen-outer-container">
            <Box {...outerStyles}>
                {expanded && (
                    <Box data-test="root-container" id="fullscreen-inner-container" {...innerStyles}>
                        {getPhaseComponent(currentPhase)}
                    </Box>
                )}
                {!centerFullScreen && <EntryPoint />}
            </Box>
            {/* <button onClick={updateSpecialists}  style={{
                borderRadius: '40px', 
                border: '2px solid black',
                padding: '30px',
            }}>
            </button> */}
        </Box>
    );
}
